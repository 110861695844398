import { Delete } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  DeleteSensorAlertDocument,
  DeleteSensorAlertMutationVariables,
  ListSensorsWithAlertsDocument,
  SensorAlert,
  UpdateSensorAlertDocument,
  UpdateSensorAlertMutationVariables,
} from "../../generated/graphql";
import CardField from "../CardField";
import ConfirmDeleteTooltip from "../ConfirmDeleteTooltip";
import { useMutation } from "@apollo/client";
import GroupContext from "../../contexts/GroupContext";
import { EditableTooltipTypes } from "../EditingTooltip";
import UserContext from "../../contexts/UserContext";
import {
  getSensorMetricByField,
  getSensorMetricByName,
  getSensorMetrics,
} from "../../utils/sensorMetrics";

enum NotificationType {
  SMS = "sms",
  EMAIL = "email",
}

type Props = {
  alert: SensorAlert;
};

export default function SensorAlertRow({ alert }: Props) {
  const group = useContext(GroupContext);
  const { user } = useContext(UserContext);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteAlert] = useMutation(DeleteSensorAlertDocument, {
    refetchQueries: [ListSensorsWithAlertsDocument],
  });
  const [updateAlert] = useMutation(UpdateSensorAlertDocument);

  if (!user) return null;

  // Alert events should be sorted descending from the query
  const lastEvent = alert.alertEvents?.items && alert.alertEvents.items[0];
  const sensorMetric = getSensorMetricByField(user, alert.field);

  function deleteHandler() {
    setConfirmDeleteOpen(false);
    const vars: DeleteSensorAlertMutationVariables = {
      input: { id: alert.id, sensorId: alert.sensorId, group },
    };
    deleteAlert({ variables: vars }).catch((e) => console.error(e));
  }

  function updateHandler(field: string) {
    return async (newValue: string) => {
      const vars: UpdateSensorAlertMutationVariables = {
        input: {
          id: alert.id,
          group,
          sensorId: alert.sensorId,
          [field]: newValue || null,
        },
      };

      await updateAlert({ variables: vars }).catch((e) => console.error(e));
    };
  }

  async function handleNotificationToggle(
    type: NotificationType,
    checked: boolean,
  ) {
    const vars: UpdateSensorAlertMutationVariables = {
      input: {
        id: alert.id,
        sensorId: alert.sensorId,
        group,
      },
    };
    if (type === NotificationType.EMAIL && checked) {
      vars.input.addEmail = true;
    } else if (type === NotificationType.EMAIL && !checked) {
      vars.input.deleteEmail = true;
    } else if (type === NotificationType.SMS && checked) {
      vars.input.addSms = true;
    } else if (type === NotificationType.SMS && !checked) {
      vars.input.deleteSms = true;
    }

    await updateAlert({ variables: vars });
  }

  return (
    <TableRow>
      <TableCell>
        <CardField
          value={sensorMetric?.name || "Error"}
          onEdit={(newValue) =>
            updateHandler("field")(
              getSensorMetricByName(user, newValue)?.field || "unknown",
            )
          }
          options={getSensorMetrics(user).map((m) => ({
            value: m.name,
            label: m.name,
          }))}
        />
      </TableCell>
      <TableCell>
        <CardField
          value={
            lastEvent ? new Date(lastEvent.timestamp).toLocaleString() : "Never"
          }
        />
      </TableCell>
      <TableCell>
        <CardField
          value={sensorMetric?.convert(alert.minThres)?.toFixed(2)}
          onEdit={(newValue) => {
            const convertedMetric = sensorMetric?.convertBack(
              parseFloat(newValue),
            );
            let val = convertedMetric?.toString() || "";
            if (isNaN(convertedMetric || NaN)) {
              val = "";
            }
            return updateHandler("minThres")(val);
          }}
          type={EditableTooltipTypes.FLOAT}
          allowClear
        />
      </TableCell>
      <TableCell>
        <CardField
          value={sensorMetric?.convert(alert.maxThres)?.toFixed(2)}
          onEdit={(newValue) => {
            const convertedMetric = sensorMetric?.convertBack(
              parseFloat(newValue),
            );
            let val = convertedMetric?.toString() || "";
            if (isNaN(convertedMetric || NaN)) {
              val = "";
            }
            return updateHandler("maxThres")(val);
          }}
          type={EditableTooltipTypes.FLOAT}
          allowClear
        />
      </TableCell>
      <TableCell>
        <CardField
          value={(alert.backoffTime / 1000 / 60).toString()}
          onEdit={(newVal) =>
            updateHandler("backoffTime")(
              (parseInt(newVal) * 60 * 1000).toString(),
            )
          }
          type={EditableTooltipTypes.INT}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          size="small"
          checked={
            !!alert.emailUsers?.find(
              (emailUser) => user?.cognitoUser?.attributes?.sub === emailUser,
            )
          }
          onChange={(_, checked) =>
            handleNotificationToggle(NotificationType.EMAIL, checked)
          }
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          size="small"
          checked={
            !!alert.smsUsers?.find(
              (smsUser) => user?.cognitoUser?.attributes?.sub === smsUser,
            )
          }
          onChange={(_, checked) =>
            handleNotificationToggle(NotificationType.SMS, checked)
          }
          disabled={!user?.cognitoUser?.attributes?.phone_number}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          size="small"
          checked={!!alert.disabled}
          onChange={(_, checked) =>
            updateHandler("disabled")(checked.toString())
          }
          color="error"
        />
      </TableCell>
      <TableCell align="center">
        <ConfirmDeleteTooltip
          open={confirmDeleteOpen}
          onCancel={() => setConfirmDeleteOpen(false)}
          onConfirm={deleteHandler}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <div>
            <Tooltip title="Delete">
              <IconButton onClick={() => setConfirmDeleteOpen(true)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </ConfirmDeleteTooltip>
      </TableCell>
    </TableRow>
  );
}
