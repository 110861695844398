import {
  Box,
  BoxProps,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import { handleEnterSubmit } from "../utils/handlers";

export type UpdatePhoneNumberInputValues = {
  number: string;
  code: string;
  badCode?: boolean;
  codeSent?: boolean;
};

type Props = {
  inputs: UpdatePhoneNumberInputValues;
  onInputsChange: (input: UpdatePhoneNumberInputValues) => void;
  submitRef: React.RefObject<HTMLButtonElement>;
} & BoxProps;

export default function UpdatePhoneNumberInputs({
  inputs,
  onInputsChange,
  submitRef,
  ...boxProps
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let helperText = "";
  if (inputs.number && !formatPhoneNumber(inputs.number))
    helperText = "Invalid Number";
  if (!!formatPhoneNumber(inputs.number))
    helperText = "Phone number must be verified before it can be added";

  return (
    <Box
      width={isSmall ? "100%" : "400px"}
      display="flex"
      flexDirection="column"
      gap={2}
      {...boxProps}
    >
      <TextField
        margin="dense"
        value={inputs.number}
        onChange={(e) => onInputsChange({ ...inputs, number: e.target.value })}
        label="Phone Number"
        type="text"
        helperText={helperText}
        onKeyDown={handleEnterSubmit(submitRef)}
        required
        error={!!inputs.number && !formatPhoneNumber(inputs.number)}
      />
      {inputs.codeSent && (
        <TextField
          value={inputs.code}
          onChange={(e) => onInputsChange({ ...inputs, code: e.target.value })}
          onKeyDown={handleEnterSubmit(submitRef)}
          label="Verification Code"
          type="text"
          error={inputs.badCode}
        />
      )}
    </Box>
  );
}
