import _ from "lodash";
import { SensorReading } from "../generated/graphql";

export const createCSVForReadings = (data: SensorReading[] | undefined) => {
  if (!data) return [];
  // Remove unneeded attributes
  return data
    .map((v) => ({
      ...v,
      __typename: undefined,
      sensorId: undefined,
    }))
    .map((v) => _.omitBy(v, _.isNil));
};
