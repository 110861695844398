import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  ListSensorsQuery,
  ListSensorsWithLastReadingDocument,
  Sensor,
} from "../../generated/graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { isNotNull } from "../../utils/typeGuards";
import AddSensorDialog from "./AddSensorDialog";
import SensorRow from "./SensorRow";
import { useQuery } from "@apollo/client";
import GroupContext from "../../contexts/GroupContext";

export default function SensorList() {
  const group = useContext(GroupContext);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { loading, data } = useQuery<ListSensorsQuery>(
    ListSensorsWithLastReadingDocument,
    {
      variables: { group },
    },
  );
  const { user, loading: userLoading } = useCurrentUser();

  const sensors = data?.listSensors?.items?.filter(isNotNull);
  const isAdmin =
    user?.cognitoUser?.signInUserSession.idToken.payload["custom:isAdmin"];

  if ((loading && !data) || userLoading) return <LoadingSkeleton />;
  else if (!sensors)
    return (
      <Typography>
        There are no sensors associated with your account.
      </Typography>
    );
  return (
    <Box display="flex" flexDirection="column">
      {isAdmin && (
        <Box flex={0} alignSelf="flex-end" pb={1}>
          <Button variant="contained" onClick={() => setAddDialogOpen(true)}>
            Add New Sensor
          </Button>
        </Box>
      )}
      <Box maxWidth="100%">
        <SensorTable sensors={sensors} isAdmin={isAdmin} />
      </Box>
      <AddSensorDialog
        open={addDialogOpen}
        onClose={() => {
          setAddDialogOpen(false);
        }}
      />
    </Box>
  );
}

type TableProps = {
  sensors: Sensor[];
  isAdmin?: boolean;
};

const SensorTable = ({ sensors, isAdmin }: TableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Serial</TableCell>
            <TableCell>Network</TableCell>
            <TableCell>Last Reading</TableCell>
            <TableCell>Color</TableCell>
            {isAdmin ? (
              <TableCell style={{ textAlign: "center" }}>Delete</TableCell>
            ) : (
              <TableCell />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sensors.map((sensor) => (
            <SensorRow key={sensor.id} sensor={sensor} isAdmin={isAdmin} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const LoadingSkeleton = () => {
  const fakeSensors = [
    { name: "1234", id: "loading1" },
    { name: "321", id: "loading2" },
    { name: "4441231", id: "loading3" },
  ] as Sensor[];
  return (
    <Skeleton width="100%" data-testid="loading-skeleton">
      <SensorTable sensors={fakeSensors} />
    </Skeleton>
  );
};
