import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export const Footer = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const currentYear = new Date().getFullYear();

  return (
    <Box
      display="flex"
      flexDirection={isSmall ? "column" : "row"}
      p={2}
      px={4}
      bgcolor="rgba(0,0,0,0.05)"
      alignItems="center"
    >
      <Typography>© {currentYear} Mabel Systems. All Rights Reserved.</Typography>
      {!isSmall && <Box flex="1" />}
      <Button component={Link} href="/tos.pdf">
        Terms of Service
      </Button>
    </Box>
  );
};
