import { Paper, PaperProps, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useRef } from "react";
import { SensorReading } from "../../generated/graphql";
import { average } from "../../utils/math";
import { isNotNull } from "../../utils/typeGuards";
import { HistoryValues } from "./HistorySelector";
import { SensorWithReadings } from "../../utils/loadSensorData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

type SensorLineChartData = ChartData<
  "line",
  (string | undefined | null)[],
  string[]
>;

type Interval = {
  time: number;
  timeMomentStart: Moment;
  timeMomentEnd: Moment;
};

const setupIntervalsInData = (timeData: ChartTime): Interval[] => {
  const intervalSize = timeData.intervalSize || 1000 * 60 * 15;
  const endTime = moment(timeData.start);
  const startTime = moment(endTime).subtract(intervalSize, "ms");
  const intervals = [
    {
      time: endTime.valueOf(),
      timeMomentEnd: endTime,
      timeMomentStart: startTime,
    },
  ];

  while (intervals[intervals.length - 1].timeMomentEnd < timeData.end) {
    const nextTime = moment(intervals[intervals.length - 1].timeMomentEnd).add(
      intervalSize,
      "ms",
    );
    intervals.push({
      time: nextTime.valueOf(),
      timeMomentEnd: nextTime,
      timeMomentStart: intervals[intervals.length - 1].timeMomentEnd,
    });
  }

  return intervals;
};

const setupSensorData = (
  readings: SensorReading[],
  attribute: keyof SensorReading,
  intervals: Interval[],
  conversion: (value: number | null | undefined) => number | null | undefined,
): (string | undefined | null)[] => {
  const intervalValues = intervals.map((i) => ({
    ...i,
    values: [] as number[],
  }));
  let idx = intervalValues.length - 1;

  readings
    ?.filter(isNotNull)
    .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
    .forEach((reading) => {
      const timestamp = moment(reading.timestamp);

      while (
        idx > 0 &&
        timestamp.isBefore(intervalValues[idx].timeMomentStart)
      ) {
        idx--;
      }

      if (
        !timestamp.isBetween(
          intervalValues[idx].timeMomentStart,
          intervalValues[idx].timeMomentEnd,
          "seconds",
        )
      )
        return;

      intervalValues[idx].values.push(reading[attribute] as number);
    });

  return intervalValues.map((i) => conversion(average(i.values))?.toFixed(2));
};

export const ChartTimesData: { [idx in HistoryValues]: ChartTimeData } = {
  [HistoryValues.HOUR]: {
    intervalSize: 1000 * 60 * 15,
    label: "Last Hour",
    hours: HistoryValues.HOUR,
  },
  [HistoryValues.DAY]: {
    intervalSize: 1000 * 60 * 15,
    label: "Last Day",
    hours: HistoryValues.DAY,
  },
  [HistoryValues.WEEK]: {
    intervalSize: 1000 * 60 * 15,
    label: "Last Week",
    hours: HistoryValues.WEEK,
  },
  [HistoryValues.THIRTYDAYS]: {
    intervalSize: 1000 * 60 * 60,
    label: "Last 30 Days",
    hours: HistoryValues.THIRTYDAYS,
  },
  [HistoryValues.CUSTOM]: {
    label: "Custom",
    hours: HistoryValues.CUSTOM,
  },
};

type ChartTimeData = {
  intervalSize?: number; // ms
  hours: number;
  label: string;
};

export type ChartTime = {
  start: Moment;
  end: Moment;
} & ChartTimeData;

export const generateChartTime = (
  value: HistoryValues,
  end: Moment,
  start?: Moment,
): ChartTime => {
  if (value === HistoryValues.CUSTOM && start && end) {
    const diff = end.diff(start);
    let intervalSize = 1000 * 60 * 15; // 15 mins default
    if (diff > 1000 * 60 * 60 * 24 * 8) {
      intervalSize = 1000 * 60 * 60;
    }

    return { ...ChartTimesData[value], start, end, intervalSize };
  }

  return {
    ...ChartTimesData[value],
    start: moment(end).subtract(value, "hour"),
    end: moment(end),
  };
};

type Props = {
  sensorReadings: SensorWithReadings[];
  attribute: keyof SensorReading;
  title: string;
  conversion: (value: number | null | undefined) => number | null | undefined;
  timeData: ChartTime;
  paperStyle?: PaperProps["sx"];
};

export default function SensorReadingsTable({
  sensorReadings,
  attribute,
  title,
  conversion,
  timeData,
  paperStyle,
}: Props) {
  const mounted = useRef(false);
  const data: SensorLineChartData = useMemo(() => {
    const intervals = setupIntervalsInData(timeData);
    const ret = {
      labels: intervals.map((i) => [
        i.timeMomentEnd.format("YY/MM/DD"),
        i.timeMomentEnd.format("h:mm:ss a"),
      ]),
      datasets: sensorReadings
        .map(({ sensor, readings }) => {
          return {
            label: sensor.name,
            data: setupSensorData(readings, attribute, intervals, conversion),
            backgroundColor: sensor.color,
            borderColor: sensor.color,
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 800,
          };
        })
        .filter((ds) => ds.data.filter((d) => d != null).length !== 0),
    };
    return ret;
  }, [timeData, attribute, sensorReadings, conversion]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  if (data.datasets.length === 0) {
    return null;
  }

  return (
    <Paper
      sx={{
        height: "500px",
        width: "100%",
        padding: 2,
        ...paperStyle,
      }}
      elevation={2}
    >
      <Typography component="h2" variant="h6" gutterBottom>
        {title}
      </Typography>
      <div style={{ height: "100%", maxHeight: "calc(100% - 50px)" }}>
        <Line
          data={data}
          options={{
            elements: { line: { tension: 0.2 } },
            interaction: {
              mode: "index",
              axis: "x",
              intersect: false,
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  autoSkip: false,
                  align: "inner",
                  callback: (_, i, ts) => {
                    return i === 0 || i === ts.length - 1
                      ? data.labels?.[i]
                      : "";
                  },
                },
              },
            },
            spanGaps: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { position: "bottom" as const },
            },
          }}
        />
      </div>
    </Paper>
  );
}
