import { useLazyQuery } from "@apollo/client";
import { Auth } from "@aws-amplify/auth";
import { Hub, HubCallback } from "@aws-amplify/core";
import { useCallback, useEffect, useState } from "react";
import { UserState } from "../contexts/UserContext";
import {
  CreateUserDocument,
  GetUserDocument,
  User as GqlUser,
} from "../generated/graphql";
import { client } from "../utils/apolloClient";

/*
 *  user should be typed below but the types are messed up for this right
 *  now. See https://github.com/aws-amplify/amplify-js/issues/4927
 */
export type User = {
  cognitoUser?: any;
  sensorUser?: GqlUser;
};

export default function useCurrentUser(): UserState {
  const [status, setStatus] = useState<{
    loading: boolean;
    error?: Error;
    user?: User;
  }>({
    loading: true,
  });

  const [getUser, { data: userData }] = useLazyQuery(GetUserDocument);

  useEffect(() => {
    if (status.loading || !userData?.getUser) return;
    setStatus((old) => ({
      ...old,
      user: { ...old.user, sensorUser: userData.getUser || undefined },
    }));
  }, [status.loading, userData]);

  const fetchNow = useCallback(
    async (
      noLoad?: boolean,
      bypassCache?: boolean,
    ): Promise<User | undefined> => {
      !noLoad && setStatus({ loading: true });
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: !!noLoad || !!bypassCache,
      });
      if (!cognitoUser) return undefined;

      const getRes = await getUser();
      if (getRes.error) throw getRes.error;
      if (getRes.data?.getUser) {
        return {
          cognitoUser,
          sensorUser: getRes.data.getUser,
        };
      }

      const createRes = await client.query({ query: CreateUserDocument });
      if (createRes.error) throw createRes.error;

      return {
        cognitoUser,
        sensorUser: createRes.data.createUser,
      };
    },
    [getUser],
  );

  useEffect(() => {
    let stale = false;

    const callFetch = (bypassCache?: boolean) => {
      fetchNow(undefined, bypassCache)
        .then((user) => {
          if (!stale) setStatus({ loading: false, user });
        })
        .catch((error) => {
          if (!stale) {
            console.error(error);
            setStatus({ loading: false, error });
          }
        });

      return () => {
        stale = true;
      };
    };

    const authListenerCallback: HubCallback = (data) => {
      if (data.payload.event === "signOut" && status.user && !stale) {
        setStatus({ loading: false });
      } else if (data.payload.event === "signIn") {
        callFetch();
      }
    };

    if (!status.user) {
      callFetch(true);
    }

    const removeListener = Hub.listen("auth", authListenerCallback);
    return () => {
      stale = true;
      removeListener();
    };
  }, [status.user, fetchNow]);

  return {
    ...status,
    refetch: () =>
      fetchNow(true)
        .then((user) => setStatus({ user, loading: false }))
        .catch((error) => setStatus({ error, loading: false })),
  };
}
