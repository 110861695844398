import { isNotNull } from "./typeGuards";

export const average = (arr?: number[]) => {
  if (!arr) return null;
  const nonNullArr = arr.filter(isNotNull);
  if (nonNullArr.length === 0) return null;
  return nonNullArr.reduce((p, c) => p + c, 0) / nonNullArr.length;
};

export const convertCelsiusToFahrenheit = (celsius?: number | null) => {
  if (typeof celsius !== "number") return celsius;
  return celsius * 1.8 + 32;
};

export const convertFahrenheitToCelsius = (fahrenheit?: number | null) => {
  if (typeof fahrenheit !== "number") return fahrenheit;
  return (fahrenheit - 32) / 1.8;
};
