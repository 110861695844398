import { ApolloProvider } from "@apollo/client";
import { Amplify } from "@aws-amplify/core";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Routes from "./navigation/Routes";
import { client } from "./utils/apolloClient";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        "email",
        "openid",
        "profile",
        "phone",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URL + "/logout",
      responseType: "code",
    },
  },
});

function App() {
  const mdTheme = createTheme({
    palette: {
      error: {
        light: "#eea8a8",
        main: "#f44336",
      },
      primary: {
        light: "#85C4ED",
        main: "#408fcc",
        dark: "#0B163A",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fdc460",
      },
    },
    typography: {
      fontFamily: [
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <Routes />
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
