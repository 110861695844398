import React from "react";
import { User } from "../hooks/useCurrentUser";

export type UserState = {
  user?: User;
  loading: boolean;
  error?: Error;
  refetch: () => Promise<void>;
};

export default React.createContext<UserState>({
  loading: false,
  refetch: async () => {},
});
