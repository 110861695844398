import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import ColorPicker from "../ColorPicker";
import ClosableErrorMessage from "../ClosableErrorMessage";
import GroupContext from "../../contexts/GroupContext";
import { useMutation } from "@apollo/client";
import {
  CreateSensorDocument,
  CreateSensorInput,
  ListSensorsWithLastReadingDocument,
} from "../../generated/graphql";

type Props = {
  open: boolean;
  onClose: () => void;
};

const emptyAddInput: CreateSensorInput = {
  id: "",
  name: "",
  color: "#9013fe",
  group: "",
};

export default function AddSensorDialog({ open, onClose }: Props) {
  const group = useContext(GroupContext);
  const [input, setInput] = useState(emptyAddInput);
  const [createSensor, { error, loading, reset }] = useMutation(
    CreateSensorDocument,
    { refetchQueries: [ListSensorsWithLastReadingDocument] }
  );

  async function handleAdd() {
    if (!input.id || !input.color || !input.name) return;
    try {
      await createSensor({ variables: { input: { ...input, group } } });
    } catch (e) {
      return;
    }
    closeAndClear();
  }

  function closeAndClear() {
    setInput(emptyAddInput);
    reset();
    onClose();
  }

  return (
    <Dialog
      open={open}
      disableEnforceFocus
      sx={{ zIndex: 1 }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add Sensor</DialogTitle>
      <DialogContent>
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <TextField
            style={{
              marginTop: "8px",
            }}
            label="ID"
            value={input.id}
            required
            onChange={(e) => setInput({ ...input, id: e.target.value })}
          />
          <TextField
            label="Name"
            value={input.name}
            required
            onChange={(e) => setInput({ ...input, name: e.target.value })}
          />
          <ColorPicker
            color={input.color}
            onChange={(color) => setInput({ ...input, color })}
            label="Color"
            allowChange
          />
          {error && (
            <ClosableErrorMessage
              message={
                error.message.includes("conditional request failed")
                  ? "Sensor ID already exists."
                  : "Error creating sensor."
              }
              onClose={() => reset()}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeAndClear()}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!input.id || !input.color || !input.name}
          onClick={handleAdd}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Add"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
