import { useContext, useEffect, useState } from "react";
import SensorReadingsTable, {
  ChartTime,
  generateChartTime,
} from "../sensor/SensorReadingsTable";
import { useParams } from "react-router-dom";
import { Box, Typography, Skeleton } from "@mui/material";
import moment from "moment";
import { HistoryValues } from "./HistorySelector";
import FullScreenSensor from "./FullScreenSensorHeaders";
import { ListSensorsDocument, SensorReading } from "../../generated/graphql";
import { useQuery } from "@apollo/client";
import loadSensorData, { SensorWithReadings } from "../../utils/loadSensorData";
import UserContext from "../../contexts/UserContext";
import { getSensorMetrics } from "../../utils/sensorMetrics";
import GroupContext from "../../contexts/GroupContext";

export default function FullScreenChart() {
  const { sensorId } = useParams<{ sensorId: string }>();
  const { user } = useContext(UserContext);
  const groupId = useContext(GroupContext);

  const [timeData, setTimeData] = useState<ChartTime>(
    generateChartTime(HistoryValues.WEEK, moment()),
  );
  const [readings, setReadings] = useState<SensorWithReadings[] | undefined>(
    undefined,
  );

  const { data, loading, error } = useQuery(ListSensorsDocument, {
    notifyOnNetworkStatusChange: true,
    variables: groupId ? { group: groupId } : undefined,
    skip: !groupId,
  });

  useEffect(() => {
    if (!data?.listSensors.items || !groupId) return;
    setReadings(undefined);

    loadSensorData(
      groupId,
      timeData.start,
      timeData.end,
      timeData.intervalSize || 1000 * 60 * 15,
      data?.listSensors.items,
    )
      .then((res) => setReadings(res))
      .catch((e) => console.error(e));
  }, [data, groupId, timeData.start, timeData.end, timeData.intervalSize]);

  if (!user || !groupId) return null;

  const table = getSensorMetrics(user)
    .filter((metric) => !metric.hideTable)
    .map((metric) => ({
      title: metric.name,
      attribute: metric.dbField,
      convert: metric.convert,
    }))
    .find((table) => table.attribute === sensorId);

  const sensors = data?.listSensors.items || [];

  const findNoData = () => {
    if (!sensors || !table || !table.attribute) return false;
    for (const sensor of sensors) {
      const rs = readings?.find((v) => v.sensor.id === sensor.id);
      if (!sensor || !rs) continue;
      for (const reading of rs.readings || []) {
        if (reading && reading[table.attribute as keyof SensorReading] != null)
          return true;
      }
    }
    return false;
  };

  const onTimeSelectChange = (chartTime: ChartTime) => {
    setTimeData(chartTime);
  };

  if (error) {
    console.error(error);
    return null;
  } else if (loading || !readings) {
    return <ChartSkeleton />;
  }

  const charts =
    !sensors || !table || !findNoData() ? (
      <Typography alignSelf="center" padding={2}>
        No sensor data to show
      </Typography>
    ) : (
      <SensorReadingsTable
        sensorReadings={readings}
        attribute={table.attribute}
        title={table.title}
        conversion={table.convert}
        timeData={timeData}
        paperStyle={{ height: "75vh", minHeight: "400px" }}
      />
    );

  return (
    <Box padding={2} display="flex" flexDirection="column">
      <FullScreenSensor
        onRefresh={() => {
          setTimeData((old) => generateChartTime(old.hours, moment()));
        }}
        timeData={timeData}
        onTimeChange={onTimeSelectChange}
      />
      {charts}
    </Box>
  );
}

const ChartSkeleton = () => (
  <Box padding={2} display="flex" flexDirection="column">
    <Skeleton width="100%" variant="rounded" height="500px" />
  </Box>
);
