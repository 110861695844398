import { Cancel, Check } from "@mui/icons-material";
import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import HtmlTooltip from "./HtmlTooltip";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactElement;
};

export default function ConfirmDeleteTooltip({
  open,
  onClose,
  onCancel,
  onConfirm,
  children,
}: Props) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <HtmlTooltip
          title={
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
            >
              <Typography fontWeight="bold" fontSize={12}>
                Confirm
              </Typography>
              <Box display="flex" gap={1}>
                <IconButton
                  data-testid="cancel"
                  size="small"
                  onClick={onCancel}
                >
                  <Cancel color="error" />
                </IconButton>
                <IconButton
                  data-testid="confirm"
                  size="small"
                  onClick={onConfirm}
                >
                  <Check color="success" />
                </IconButton>
              </Box>
            </Box>
          }
          open={open}
          arrow
          onClose={onClose}
          disableHoverListener
          disableFocusListener
          disableTouchListener
        >
          {children}
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
}
