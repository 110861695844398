import { UpdateUserDocument, User as SensorUser } from "../generated/graphql";
import { User } from "../hooks/useCurrentUser";
import { client } from "./apolloClient";

export type SensorPreferences = {
  offlineAlertEmail?: boolean;
  offlineAlertSMS?: boolean;
  tempUnit?: "C" | "F";
};

export const getPreferences = (user?: User): SensorPreferences => ({
  offlineAlertEmail: user?.sensorUser?.offlineAlertEmail || undefined,
  offlineAlertSMS: user?.sensorUser?.offlineAlertSMS || undefined,
  tempUnit: (user?.sensorUser?.tempUnit as "C" | "F") || undefined,
});

export const updatePreferences = async (
  user: SensorUser,
  attribute: keyof SensorPreferences,
  value: unknown,
) => {
  await client.mutate({
    mutation: UpdateUserDocument,
    variables: {
      input: {
        [attribute]: value,
      },
    },
    optimisticResponse: (inp) => ({ updateUser: { ...inp, ...user } }),
  });
};
