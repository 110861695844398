import { Box, CircularProgress } from "@mui/material";

export default function FullScreenLoading() {
  return (
    <Box
      borderRadius={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
      padding={2}
      margin={2}
      bgcolor="white"
      gap={2}
    >
      <Box
        component="img"
        src="/mabel_logo_colour.png"
        alt="Mabel Logo"
        width={350}
        maxWidth={"100%"}
      />
      <CircularProgress />
    </Box>
  );
}
