import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

type Props = {
  message: string;
  onClose?: () => void;
};

export default function ClosableErrorMessage({ message, onClose }: Props) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ backgroundColor: "error.light" }}
      p={2}
      borderRadius={1}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <ErrorIcon fontSize="small" />
        <Typography flexWrap="wrap">{message}</Typography>
      </Box>
      {onClose && (
        <Button size="small" sx={{ color: "black" }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </Button>
      )}
    </Box>
  );
}
