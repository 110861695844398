import { ChangeEventHandler, RefObject } from "react";

export function handleEnterSubmit(submitRef: RefObject<HTMLButtonElement>) {
  return (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitRef.current?.click();
    }
  };
}

export function handleFloatChange(
  setFunc: React.Dispatch<React.SetStateAction<string>> | ((v: string) => void),
) {
  const handler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    if (
      e.target.value !== "-" &&
      e.target.value !== "." &&
      (e.target.value[e.target.value.length - 1] !== "." ||
        e.target.value.slice(0, e.target.value.length - 1).includes("."))
    ) {
      const num = parseFloat(e.target.value);
      const val = !isNaN(num) ? num.toString() : "";
      setFunc(val);
    } else {
      setFunc(e.target.value);
    }
  };
  return handler;
}

export function handleIntegerChange(
  setFunc: React.Dispatch<React.SetStateAction<string>> | ((v: string) => void),
) {
  const handler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    const num = parseInt(e.target.value);
    const val = !isNaN(num) ? num.toString() : "";
    setFunc(val);
  };
  return handler;
}
