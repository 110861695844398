import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import FaceIcon from "@mui/icons-material/Face";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Auth } from "@aws-amplify/auth";
import getInitials from "../utils/getInitials";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../hooks/useCurrentUser";

type Props = {
  user?: User | null;
};

export default function AvatarDropdown({ user }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const initials = getInitials(user);
  const navigation = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton size="small" onClick={(e) => setAnchor(e.currentTarget)}>
          <Avatar
            sx={{
              bgcolor: "white",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {initials ? (
              initials
            ) : (
              <AccountCircleIcon data-testid="AccountIcon" fontSize="large" />
            )}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            if (location.pathname !== "/profile") navigation("/profile");
          }}
        >
          <FaceIcon sx={{ marginRight: 1 }} />
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            Auth.signOut().catch((e) => console.error(e));
          }}
        >
          <LogoutIcon sx={{ marginRight: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
