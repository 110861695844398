import { Error } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function ErrorMessage() {
  return (
    <Box gap={1} display="flex" flexDirection="row" alignSelf="center">
      <Error />
      <Typography>Error</Typography>
    </Box>
  );
}
