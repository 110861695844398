import { Check } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import {
  handleEnterSubmit,
  handleFloatChange,
  handleIntegerChange,
} from "../utils/handlers";
import { LabelValuePair } from "./CardField";
import HtmlTooltip from "./HtmlTooltip";

type Props = {
  initialValue?: string;
  open: boolean;
  onClose: (newVal?: string) => Promise<void>;
  type?: EditableTooltipTypes;
  options?: LabelValuePair[];
  children: React.ReactElement;
};

export enum EditableTooltipTypes {
  FLOAT = "float",
  INT = "int",
  PHONE = "phone",
}

export default function EditableTooltip({
  open,
  initialValue,
  onClose,
  options,
  type,
  children,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState<string>(initialValue || "");
  const confirmRef = useRef<HTMLButtonElement>(null);
  function handler(setFunc: React.Dispatch<React.SetStateAction<string>>) {
    const defaultHandler: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e) => setValue(e.target.value);

    const phoneHandler: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
      if (!formatPhoneNumber(e.target.value)) setDisabled(true);
      else setDisabled(false);
      setValue(e.target.value);
    };

    switch (type) {
      case EditableTooltipTypes.FLOAT:
        return handleFloatChange(setFunc);
      case EditableTooltipTypes.INT:
        return handleIntegerChange(setFunc);
      case EditableTooltipTypes.PHONE:
        return phoneHandler;
      default:
        return defaultHandler;
    }
  }

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  return (
    <ClickAwayListener onClickAway={() => onClose()}>
      <div style={{ flex: 1 }}>
        <HtmlTooltip
          arrow
          open={open}
          title={
            <Box display="flex" alignItems="center">
              <TextField
                disabled={loading}
                prefix="+1"
                size="small"
                margin="none"
                value={value}
                autoFocus
                select={!!options}
                SelectProps={{ native: true }}
                onKeyDown={handleEnterSubmit(confirmRef)}
                onChange={handler(setValue)}
                InputProps={{
                  sx: { fontSize: 12 },
                  startAdornment: type === EditableTooltipTypes.PHONE && (
                    <InputAdornment position="start">
                      <Typography fontSize={12}>+1</Typography>
                    </InputAdornment>
                  ),
                }}
              >
                {options?.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </TextField>
              {loading ? (
                <CircularProgress sx={{ marginLeft: 1 }} size={16} />
              ) : (
                <IconButton
                  ref={confirmRef}
                  disabled={disabled}
                  color="success"
                  sx={{ height: "25px", width: "25px" }}
                  onClick={async () => {
                    setLoading(true);
                    await onClose(value);
                    setLoading(false);
                  }}
                  data-testid="confirm"
                >
                  <Check />
                </IconButton>
              )}
            </Box>
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={() => onClose()}
        >
          {children}
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
}
