import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import ClosableErrorMessage from "./ClosableErrorMessage";
import UpdatePhoneNumberInputs, {
  UpdatePhoneNumberInputValues,
} from "./UpdatePhoneNumberInputs";
import { User } from "../hooks/useCurrentUser";

const emptyInput = { code: "", number: "" };

type LoadingValues = {
  resending?: boolean;
  submit?: boolean;
};

type Props = {
  open: boolean;
  user: User;
  onClose: () => void;
  refetchUser: () => Promise<void>;
} & DialogProps;

export default function UpdatePhoneNumberDialog({
  open,
  user,
  onClose,
  refetchUser,
  ...props
}: Props) {
  const submitRef = useRef(null);
  const [loading, setLoading] = useState<LoadingValues>({});
  const [error, setError] = useState("");
  const [inputs, setInputs] =
    useState<UpdatePhoneNumberInputValues>(emptyInput);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  async function closeAndClear() {
    setInputs(emptyInput);
    setError("");
    setLoading({});
    onClose();
  }

  async function deleteNumber() {
    setLoading((previous) => ({ ...previous, resending: true }));
    await Auth.deleteUserAttributes(user.cognitoUser, ["phone_number"]);
    await refetchUser();
    setLoading((previous) => ({ ...previous, resending: false }));
    closeAndClear();
  }

  async function resendCode() {
    setLoading((previous) => ({ ...previous, resending: true }));
    try {
      await Auth.verifyUserAttribute(user.cognitoUser, "phone_number");
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
    }
    setLoading((previous) => ({ ...previous, resending: false }));
  }

  async function startVerification() {
    setLoading((previous) => ({ ...previous, submit: true }));
    try {
      await Auth.updateUserAttributes(user.cognitoUser, {
        phone_number: "+1" + inputs.number,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
      await refetchUser();
      setLoading((previous) => ({ ...previous, submit: false }));
      return;
    }
    await refetchUser();
    setInputs((i) => ({ ...i, codeSent: true }));
    setLoading((previous) => ({ ...previous, submit: false }));
  }

  async function verifyCode() {
    setLoading((previous) => ({ ...previous, submit: true }));
    try {
      await Auth.verifyUserAttributeSubmit(
        user.cognitoUser,
        "phone_number",
        inputs.code,
      );
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "CodeMismatchException") {
          setInputs((i) => ({ ...i, badCode: true }));
        }
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
      setLoading((previous) => ({ ...previous, submit: false }));
      return;
    }

    await refetchUser();
    setLoading((previous) => ({ ...previous, submit: false }));
    closeAndClear();
  }

  let buttonContent: string | JSX.Element = "Submit";
  if (loading.submit) {
    buttonContent = (
      <Box width="60px" display="flex" justifyContent="center">
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  } else if (!inputs.codeSent) {
    buttonContent = "Verify";
  }

  let resendButtonContent: string | JSX.Element = "Resend";
  if (loading.resending) {
    resendButtonContent = (
      <Box width="60px" display="flex" justifyContent="center">
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  } else if (!inputs.codeSent) resendButtonContent = "Delete";

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth={isSmall}
      {...props}
    >
      <DialogTitle>Update Account Phone Number</DialogTitle>
      <DialogContent>
        <UpdatePhoneNumberInputs
          inputs={inputs}
          width={isSmall ? "100%" : "400px"}
          onInputsChange={(inp) => {
            if (inp.number !== inputs.number)
              setInputs({ ...inp, codeSent: false, code: "" });
            else setInputs(inp);
          }}
          submitRef={submitRef}
        />
        {error && (
          <Box mt={1} maxWidth="400px">
            <ClosableErrorMessage
              message={error}
              onClose={() => setError("")}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading.resending}
          onClick={() => (inputs.codeSent ? resendCode() : deleteNumber())}
        >
          {resendButtonContent}
        </Button>
        <Box flex={1} />
        <Button disabled={loading.submit} onClick={() => closeAndClear()}>
          Cancel
        </Button>
        <Button
          ref={submitRef}
          disabled={!formatPhoneNumber(inputs.number)}
          variant="contained"
          onClick={() => {
            inputs.codeSent ? verifyCode() : startVerification();
          }}
        >
          {buttonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
