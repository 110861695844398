import { Download, Refresh, Settings } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from "react";
import DownloadSensorDataDialog from "./DownloadSensorDataDialog";
import HistorySelector from "./HistorySelector";
import { ChartTime } from "./SensorReadingsTable";
import { useNavigate } from "react-router-dom";
import { Sensor } from "../../generated/graphql";

type Props = {
  sensors?: Sensor[];
  onRefresh: () => void;
  timeData: ChartTime;
  onTimeChange: (timeData: ChartTime) => void;
};

export default function SensorTablesHeader({
  sensors,
  onRefresh,
  timeData,
  onTimeChange,
}: Props) {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const iconButtons = (
    <Box>
      <IconButton
        onClick={() => setDownloadDialogOpen(true)}
        disabled={!sensors}
        color="inherit"
      >
        <Download />
      </IconButton>
      <IconButton color="inherit" onClick={onRefresh}>
        <Refresh />
      </IconButton>
      <IconButton
        color="inherit"
        disabled={!sensors}
        onClick={() => navigate("/sensorsettings#sensors")}
      >
        <Settings />
      </IconButton>
    </Box>
  );

  let buttons = (
    <Grid item container sm={8} display="flex" justifyContent="flex-end">
      <Grid item>
        <HistorySelector value={timeData} onChange={onTimeChange} />
      </Grid>
      <Grid item>{iconButtons}</Grid>
    </Grid>
  );

  if (smallScreen) {
    buttons = (
      <Grid container item xs={12} spacing={1} display="flex">
        <Grid item flex={1}>
          <HistorySelector value={timeData} onChange={onTimeChange} />
        </Grid>
        <Grid item>{iconButtons}</Grid>
      </Grid>
    );
  }

  return (
    <Box mb={2}>
      <Grid flex={1} display="flex" container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography flex={1} variant="h6" lineHeight={2}>
            Sensor Charts
          </Typography>
        </Grid>
        {buttons}
      </Grid>
      <DownloadSensorDataDialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        sensors={sensors}
      />
    </Box>
  );
}
