import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Auth } from "@aws-amplify/auth";
import { AuthOptions, AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const link = ApolloLink.from([
  createAuthLink({
    url: process.env.REACT_APP_API_URL as string,
    region: "us-east-1",
    auth,
  }),
  httpLink,
]);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});
