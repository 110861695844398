import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import EditingTooltip, { EditableTooltipTypes } from "./EditingTooltip";

export type LabelValuePair = { label: string; value: string };

type CardFieldProps = {
  value?: string;
  onEdit?: (newVal: string) => Promise<void>;
  type?: EditableTooltipTypes;
  options?: LabelValuePair[];
};

const EditableText = ({ value, onEdit, type, options }: CardFieldProps) => {
  const [editOpen, setEditOpen] = useState(false);

  function handleEditOpen() {
    setEditOpen(true);
  }

  return onEdit ? (
    <EditingTooltip
      open={editOpen}
      initialValue={value}
      onClose={async (newVal) => {
        newVal && (await onEdit(newVal));
        setEditOpen(false);
      }}
      type={type}
      options={options}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Typography fontSize={18}>{value || "-"}</Typography>
        <IconButton onClick={() => handleEditOpen()} size="small">
          <Edit fontSize={"small"} />
        </IconButton>
      </Box>
    </EditingTooltip>
  ) : (
    <Typography fontSize={18}>{value || "-"}</Typography>
  );
};

export default EditableText;
