import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import ClosableErrorMessage from "./ClosableErrorMessage";
import UpdateEmailInputs, { UpdateEmailInputValues } from "./UpdateEmailInputs";
import { isValidEmail } from "../utils/errorHelpers";
import { CheckEmailBlacklistedDocument } from "../generated/graphql";
import { useLazyQuery } from "@apollo/client";
import { User } from "../hooks/useCurrentUser";

const emptyInput = { code: "", email: "" };

type LoadingValues = {
  resending?: boolean;
  submit?: boolean;
  email?: boolean;
};

type Props = {
  open: boolean;
  user: User;
  onClose: () => void;
  refetchUser: () => Promise<void>;
} & DialogProps;

export default function UpdateEmailDialog({
  open,
  user,
  onClose,
  refetchUser,
  ...props
}: Props) {
  const submitRef = useRef(null);
  const [loading, setLoading] = useState<LoadingValues>({});
  const [error, setError] = useState("");
  const [emailBlocked, setEmailBlocked] = useState(false);
  const [inputs, setInputs] = useState<UpdateEmailInputValues>(emptyInput);
  const debounceTimerRef = useRef<NodeJS.Timeout>();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [checkEmailBlacklisted] = useLazyQuery(CheckEmailBlacklistedDocument);

  async function closeAndClear() {
    setInputs(emptyInput);
    setError("");
    setLoading({});
    onClose();
  }

  async function resendCode() {
    setLoading((previous) => ({ ...previous, resending: true }));
    try {
      await Auth.verifyUserAttribute(user.cognitoUser, "email");
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
    }
    setLoading((previous) => ({ ...previous, resending: false }));
  }

  async function startVerification() {
    setLoading((previous) => ({ ...previous, submit: true }));
    try {
      await Auth.updateUserAttributes(user.cognitoUser, {
        email: inputs.email,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
      await refetchUser();
      setLoading((previous) => ({ ...previous, submit: false }));
      return;
    }
    await refetchUser();
    setInputs((i) => ({ ...i, codeSent: true }));
    setLoading((previous) => ({ ...previous, submit: false }));
  }

  async function verifyCode() {
    setLoading((previous) => ({ ...previous, submit: true }));
    try {
      await Auth.verifyUserAttributeSubmit(
        user.cognitoUser,
        "email",
        inputs.code,
      );
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "CodeMismatchException") {
          setInputs((i) => ({ ...i, badCode: true }));
        }
        setError(e.message);
      } else {
        setError("Unknown Error");
      }
      setLoading((previous) => ({ ...previous, submit: false }));
      return;
    }

    await refetchUser();
    setLoading((previous) => ({ ...previous, submit: false }));
    closeAndClear();
  }

  const handleBlacklistError = (err: unknown) => {
    if (err instanceof Error) {
      setError(err.message);
    } else {
      setError("Unknown Error");
    }
    setEmailBlocked(true);
    setLoading((previous) => ({ ...previous, email: false }));
  };

  let buttonContent: string | JSX.Element = "Submit";
  if (loading.submit) {
    buttonContent = (
      <Box width="60px" display="flex" justifyContent="center">
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  } else if (!inputs.codeSent) {
    buttonContent = "Verify";
  }

  let resendButtonContent: string | JSX.Element = "Resend";
  if (loading.resending) {
    resendButtonContent = (
      <Box width="60px" display="flex" justifyContent="center">
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  } else if (!inputs.codeSent) resendButtonContent = "Delete";

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth={isSmall}
      {...props}
    >
      <DialogTitle>Update Account Email</DialogTitle>
      <DialogContent>
        <UpdateEmailInputs
          inputs={inputs}
          emailLoading={loading.email}
          emailBlocked={emailBlocked}
          width={isSmall ? "100%" : "400px"}
          onInputsChange={(inp) => {
            if (inp.email !== inputs.email)
              setInputs({ ...inp, codeSent: false, code: "" });
            else setInputs(inp);

            if (debounceTimerRef.current) {
              clearTimeout(debounceTimerRef.current);
            }

            setEmailBlocked(false);
            setLoading((previous) => ({ ...previous, email: true }));

            debounceTimerRef.current = setTimeout(async () => {
              let res;
              try {
                res = await checkEmailBlacklisted({
                  variables: { address: inp.email },
                });
              } catch (e) {
                handleBlacklistError(e);
                return;
              }
              if (res.error) {
                handleBlacklistError(res.error);
                return;
              }
              if (res.data?.checkEmailBlacklisted) setEmailBlocked(true);
              setLoading((previous) => ({ ...previous, email: false }));
            }, 1000);
          }}
          submitRef={submitRef}
        />
        {error && (
          <Box mt={1} maxWidth="400px">
            <ClosableErrorMessage
              message={error}
              onClose={() => setError("")}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {inputs.codeSent && (
          <Button
            disabled={loading.resending || loading.email || emailBlocked}
            onClick={() => resendCode()}
          >
            {resendButtonContent}
          </Button>
        )}
        <Box flex={1} />
        <Button disabled={loading.submit} onClick={() => closeAndClear()}>
          Cancel
        </Button>
        <Button
          ref={submitRef}
          disabled={
            !isValidEmail(inputs.email) || loading.email || emailBlocked
          }
          variant="contained"
          onClick={() => {
            inputs.codeSent ? verifyCode() : startVerification();
          }}
        >
          {buttonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
