import { useContext, useEffect, useState } from "react";
import SensorReadingsTable, {
  ChartTime,
  generateChartTime,
} from "../sensor/SensorReadingsTable";
import { Box, Grid, Typography, Skeleton, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import moment from "moment";
import { HistoryValues } from "./HistorySelector";
import SensorTablesHeader from "./SensorTablesHeader";
import { ListSensorsDocument, SensorReading } from "../../generated/graphql";
import GroupContext from "../../contexts/GroupContext";
import { useQuery } from "@apollo/client";
import loadSensorData, { SensorWithReadings } from "../../utils/loadSensorData";
import UserContext from "../../contexts/UserContext";
import { getSensorMetrics } from "../../utils/sensorMetrics";

type Table = {
  title: string;
  attribute: keyof SensorReading;
  convert: (value?: number | null) => number | null | undefined;
};

function checkNoData(sensorReadings: SensorWithReadings[]) {
  return (
    sensorReadings.length <= 0 ||
    sensorReadings.every(({ readings }) => !readings || readings.length <= 0)
  );
}

export default function AllReadingsTables() {
  const { user } = useContext(UserContext);
  const group = useContext(GroupContext);

  const [timeData, setTimeData] = useState<ChartTime>(
    generateChartTime(HistoryValues.WEEK, moment()),
  );
  const [readings, setReadings] = useState<SensorWithReadings[] | undefined>(
    undefined,
  );

  const { data, loading, error, refetch } = useQuery(ListSensorsDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      group,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, timeData]);

  useEffect(() => {
    if (!data?.listSensors.items) return;
    setReadings(undefined);

    loadSensorData(
      group,
      timeData.start,
      timeData.end,
      timeData.intervalSize || 1000 * 60 * 15,
      data?.listSensors.items,
    )
      .then((res) => setReadings(res))
      .catch((e) => console.error(e));
  }, [data, group, timeData.start, timeData.end, timeData.intervalSize]);

  if (!user) return null;

  const tables: Table[] = getSensorMetrics(user)
    .filter((metric) => !metric.hideTable)
    .map((metric) => ({
      title: metric.name,
      attribute: metric.dbField,
      convert: metric.convert,
    }));

  const sensors = data?.listSensors.items || [];

  const findNoDataTable = (table: Table) => {
    if (!sensors) return false;
    for (const sensor of sensors) {
      const rs = readings?.find((v) => v.sensor.id === sensor.id);
      if (!sensor || !rs) continue;
      for (const reading of rs.readings || []) {
        if (reading && reading?.[table.attribute] != null) return true;
      }
    }
    return false;
  };

  const onTimeSelectChange = (chartTime: ChartTime) => {
    setTimeData(chartTime);
  };

  const handleChartClick = (sensorId: string) => {
    const newUrl = `${window.location.origin}/sensor/${sensorId}/fullscreen?group=${group}`;
    window.open(newUrl, "_blank");
  };

  if (error) {
    console.error(error);
    return null;
  } else if (loading || !readings) {
    return <ChartSkeleton />;
  }

  let charts =
    !sensors || checkNoData(readings) ? (
      <Typography alignSelf="center" padding={2}>
        No sensor data to show
      </Typography>
    ) : (
      <Grid container spacing={2}>
        {tables.filter(findNoDataTable).map((table) => (
          <Grid
            key={table.attribute}
            item
            xs={12}
            lg={6}
            style={{ position: "relative" }} // Add this style
          >
            <SensorReadingsTable
              sensorReadings={readings}
              attribute={table.attribute}
              title={table.title}
              conversion={table.convert}
              timeData={timeData}
            />
            <IconButton
              style={{
                position: "absolute",
                top: 25,
                right: 8,
                zIndex: 1,
              }}
              onClick={() => handleChartClick(table.attribute as string)}
            >
              <FullscreenIcon />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    );

  return (
    <Box padding={2} display="flex" flexDirection="column">
      <SensorTablesHeader
        sensors={sensors}
        onRefresh={() => {
          setTimeData((old) => generateChartTime(old.hours, moment()));
        }}
        timeData={timeData}
        onTimeChange={onTimeSelectChange}
      />
      {charts}
    </Box>
  );
}

const ChartSkeleton = () => {
  return (
    <Box padding={2} display="flex" flexDirection="column">
      <Typography flex={1} variant="h6" lineHeight={2} pb={2}>
        Sensor Charts
      </Typography>
      <Grid container spacing={2}>
        {[1, 2, 3, 4].map((table) => (
          <Grid key={table} item xs={12} lg={6}>
            <Skeleton width="100%" variant="rounded" height="500px"></Skeleton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
