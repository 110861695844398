import { Box, MenuItem, TextField } from "@mui/material";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { CreateSensorAlertInput, Sensor } from "../../generated/graphql";
import { handleFloatChange, handleIntegerChange } from "../../utils/handlers";
import { getSensorMetrics } from "../../utils/sensorMetrics";

export type TempAlertInput = {
  maxThres?: string;
  minThres?: string;
  backoffTime?: number;
} & Omit<CreateSensorAlertInput, "maxThres" | "minThres" | "backoffTime">;

type Props = {
  fields: TempAlertInput;
  onInputChange: (fields: TempAlertInput) => void;
  sensors?: Sensor[];
};

export default function AddSensorAlertInputs({
  fields,
  onInputChange,
  sensors,
}: Props) {
  const { user } = useContext(UserContext);
  if (!user) return null;

  return (
    <Box maxWidth="350px" display="flex" flexDirection="column" gap={2}>
      <TextField
        value={fields.sensorId || ""}
        onChange={(e) => {
          const dbSensor = sensors?.find((s) => s.id === e.target.value);
          dbSensor &&
            onInputChange({
              ...fields,
              sensorId: e.target.value,
              group: dbSensor.group,
            });
        }}
        label="Sensor"
        margin="dense"
        select
        disabled={!sensors || sensors?.length === 0}
        required
        inputProps={{
          style: { textAlign: "center" },
        }}
      >
        {sensors?.map((s) => (
          <MenuItem key={s.id} value={s.id}>
            {s.name}
          </MenuItem>
        ))}
        {(!sensors || sensors?.length === 0) && <MenuItem value=""></MenuItem>}
      </TextField>
      <TextField
        value={fields.field}
        select
        onChange={(e) => onInputChange({ ...fields, field: e.target.value })}
        label="Metric"
        required
      >
        {getSensorMetrics(user).map((m) => (
          <MenuItem key={m.field} value={m.field}>
            {m.name}
          </MenuItem>
        ))}
      </TextField>
      <Box display="flex" gap={2}>
        <TextField
          label="Minimum Allowed"
          value={fields.minThres || ""}
          onChange={handleFloatChange((v: string) =>
            onInputChange({
              ...fields,
              minThres: v,
            }),
          )}
          inputProps={{
            "data-testid": "1234",
          }}
        />
        <TextField
          label="Maximum Allowed"
          value={fields.maxThres || ""}
          onChange={handleFloatChange((v: string) =>
            onInputChange({
              ...fields,
              maxThres: v,
            }),
          )}
        />
      </Box>
      <TextField
        label="Backoff Period"
        helperText="After being triggered, another of this alert will not be sent for this many minutes."
        value={fields.backoffTime ? fields.backoffTime / 1000 / 60 : ""}
        required
        onChange={handleIntegerChange((v: string) =>
          onInputChange({ ...fields, backoffTime: parseInt(v) * 60 * 1000 }),
        )}
      />
    </Box>
  );
}
