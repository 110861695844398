import { Moment } from "moment";
import {
  ListSensorReadingsDocument,
  Maybe,
  Sensor,
  SensorReading,
} from "../generated/graphql";
import { client } from "./apolloClient";
import moment from "moment";
import { isNotUndefined } from "./typeGuards";

export type SensorWithReadings = {
  sensor: Sensor;
  readings: SensorReading[];
};

export default async function loadSensorData(
  group: string,
  start: Moment,
  end: Moment,
  intervalSize: number,
  sensors?: Maybe<Sensor[]>,
): Promise<SensorWithReadings[]> {
  if (!sensors) return [];

  const days = [
    {
      start: start.clone().subtract(intervalSize, "ms"),
      end: moment(start).endOf("day"),
    },
  ];
  while (days[days.length - 1].end.isBefore(end)) {
    days.push({
      start: days[days.length - 1].end.clone().startOf("day").add(1, "day"),
      end: days[days.length - 1].end.clone().add(1, "day"),
    });
  }
  days[days.length - 1].end = end;

  const sensorProms = sensors.map(async (sensor) => {
    const sensorReadings = await Promise.all(
      days.map(async (day) => {
        const start = moment(day.start).utc().format("YYYY-MM-DDTHH:mm:ssZ");
        const end = moment(day.end).utc().format("YYYY-MM-DDTHH:mm:ssZ");

        return client.query({
          query: ListSensorReadingsDocument,
          variables: {
            sensorId: sensor.id,
            group,
            between: [start, end],
          },
        });
      }),
    );

    const readings: SensorReading[] = [];
    sensorReadings.forEach((v) => {
      readings.push(
        ...(v.data.listSensorReadings.items
          ?.filter(isNotUndefined)
          .map((v) => ({ ...v, sensorId: sensor.id })) || []),
      );
    });
    return { sensor: sensor, readings };
  });

  return Promise.all(sensorProms);
}
