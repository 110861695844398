import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SensorAlertsList from "../components/sensor/SensorAlertsList";
import SensorList from "../components/sensor/SensorList";
import TabPanel from "../components/TabPanel";
import { ArrowBack } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SensorSettings() {
  const theme = useTheme();
  const navigation = useNavigate();
  const tS = useMediaQuery(theme.breakpoints.down("sm"));
  const { hash } = useLocation();
  const index = hash === "#alerts" ? 2 : 1;

  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        flex={1}
        maxWidth={1200}
        width="100%"
        gap={2}
      >
        <Box display="flex" alignItems="center" pb={2} gap={2}>
          <IconButton onClick={() => navigation(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography component="h5" variant="h5">
            Sensor Settings
          </Typography>
        </Box>
        <Tabs sx={{ borderBottom: 1, borderColor: "gray" }} value={index}>
          <Tab
            component={Link}
            label="Sensors"
            value={1}
            to="/sensorsettings#sensors"
            replace={true}
          />
          <Tab
            component={Link}
            to="/sensorsettings#alerts"
            label="Alerts"
            value={2}
            replace={true}
          />
        </Tabs>
        <TabPanel value={index} index={1} boxProps={{ px: tS ? 0 : 2 }}>
          <SensorList />
        </TabPanel>
        <TabPanel value={index} index={2} boxProps={{ px: tS ? 0 : 2 }}>
          <SensorAlertsList />
        </TabPanel>
      </Box>
    </Box>
  );
}
