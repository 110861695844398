import { User } from "../hooks/useCurrentUser";

const getInitials = (user?: User | null) => {
  if (!user) return "";
  let initials = user?.cognitoUser?.attributes?.given_name
    ? user.cognitoUser.attributes.given_name.substring(0, 1)?.toUpperCase()
    : "";
  initials += user?.cognitoUser?.attributes?.family_name
    ? user.cognitoUser.attributes.family_name.substring(0, 1)?.toUpperCase()
    : "";
  return initials;
};

export default getInitials;
