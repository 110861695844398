import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { User } from "../../hooks/useCurrentUser";
import {
  getPreferences,
  SensorPreferences,
  updatePreferences,
} from "../../utils/preferences";

type Props = { user: User; refetchUser: () => Promise<void> };

const Preferences = ({ user, refetchUser }: Props) => {
  async function updateUserPreference(
    attribute: keyof SensorPreferences,
    value: unknown,
  ) {
    if (!user.sensorUser) return;
    await updatePreferences(user.sensorUser, attribute, value);
    await refetchUser();
  }

  const preferences = getPreferences(user);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontWeight={500} fontSize={20} mb={2}>
        Preferences
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            Offline Sensor Alerts Delivery
          </Typography>
          <Box justifyContent="flex-start">
            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                label="Email"
                control={
                  <Checkbox
                    size="small"
                    checked={preferences.offlineAlertEmail || false}
                    onChange={async (_, checked) => {
                      await updateUserPreference("offlineAlertEmail", checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="SMS"
                control={
                  <Checkbox
                    size="small"
                    checked={preferences.offlineAlertSMS || false}
                    disabled={!user?.cognitoUser?.attributes?.phone_number}
                    onChange={async (_, checked) => {
                      await updateUserPreference("offlineAlertSMS", checked);
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            {" "}
            Temperature Unit{" "}
          </Typography>
          <Box justifyContent="flex-start">
            <TextField
              sx={{ minWidth: 150 }}
              size="small"
              select
              value={preferences.tempUnit || "C"}
              onChange={async (e) =>
                await updateUserPreference("tempUnit", e.target.value)
              }
            >
              <MenuItem value="C">Celsius</MenuItem>
              <MenuItem value="F">Fahrenheit</MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Preferences;
