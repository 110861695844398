import { Auth } from "@aws-amplify/auth";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FullScreenLoading from "../components/FullScreenLoading";
import useCurrentUser from "../hooks/useCurrentUser";

export const Logout = () => {
  const { user, loading } = useCurrentUser();
  const navigate = useNavigate();

  if (loading) return <FullScreenLoading />;
  else if (user) {
    navigate("/");
    return null;
  }

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography>You have logged out.</Typography>
      <Button onClick={() => void Auth.federatedSignIn()}>Sign Back In</Button>
    </Box>
  );
};

export default Logout;
