import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { UpdateUserDocument } from "../generated/graphql";
import ClosableErrorMessage from "./ClosableErrorMessage";

type Props = {
  firstTime: boolean;
  refetch: () => void;
};

const AcceptTos = ({ firstTime, refetch }: Props) => {
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [acceptTos, { loading, error }] = useMutation(UpdateUserDocument, {
    variables: { input: { acceptedTos: true } },
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
      bgcolor="lightgrey"
    >
      <Box
        borderRadius={2}
        bgcolor="white"
        display="flex"
        flexDirection="column"
        padding={2}
        maxWidth={400}
        gap={4}
      >
        <Typography fontSize={24}>Terms Of Service</Typography>
        {!firstTime && (
          <Typography fontSize={12} color="red">
            Please review and accept our updated Terms of Service
          </Typography>
        )}
        <Box display="flex" alignItems="center" flexDirection="row">
          <Checkbox
            value={acceptedTos}
            onChange={(_, checked) => setAcceptedTos(checked)}
          />
          <Typography fontSize={12}>
            I accept and agree to the{" "}
            <a href="/tos.pdf" target="_blank">
              Terms of Service
            </a>
            .
          </Typography>
        </Box>
        {error && <ClosableErrorMessage message={error.message} />}
        <Button
          fullWidth
          variant="contained"
          disabled={loading || !acceptedTos}
          onClick={async () => {
            await acceptTos();
            refetch();
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Continue"}
        </Button>
      </Box>
    </Box>
  );
};

export default AcceptTos;
