import { Auth } from "@aws-amplify/auth";
import { Routes as RouterRoutes, Route, useLocation } from "react-router-dom";
import AcceptTos from "../components/AcceptTos";
import FullScreenLoading from "../components/FullScreenLoading";
import FullScreenChart from "../components/sensor/FullScreenChart";
import useCurrentUser from "../hooks/useCurrentUser";
import Home from "../routes/Home";
import Logout from "../routes/Logout";
import Profile from "../routes/Profile";
import SensorSettings from "../routes/SensorSettings";
import MainNavigation from "./MainNavigation";

export default function Routes() {
  const userState = useCurrentUser();
  const { user, loading: userLoading, error, refetch } = userState;
  const location = useLocation();

  if (error) console.error(error);

  if (userLoading) {
    return <FullScreenLoading />;
  } else if (!user && location.pathname !== "/logout") {
    Auth.federatedSignIn();
    return null;
  } else if (user && !user?.sensorUser?.acceptedTos) {
    return (
      <AcceptTos
        firstTime={!user?.sensorUser?.acceptedTosDate}
        refetch={refetch}
      />
    );
  }

  return (
    <RouterRoutes>
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<MainNavigation userState={userState} />}>
        <Route path="profile" element={<Profile />} />
        <Route path="sensorsettings/*" element={<SensorSettings />} />
        <Route path="*" element={<Home />} />
        <Route path="sensor/:sensorId/fullscreen" element={<FullScreenChart />} />
      </Route>
    </RouterRoutes>
  );
}
