import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useContext, useEffect, useReducer, useRef } from "react";
import {
  CreateSensorAlertDocument,
  CreateSensorAlertMutationVariables,
  ListSensorsWithAlertsDocument,
  Sensor,
} from "../../generated/graphql";
import ClosableErrorMessage from "../ClosableErrorMessage";
import AddSensorAlertInputs, { TempAlertInput } from "./AddSensorAlertInputs";
import { useMutation } from "@apollo/client";
import { getSensorMetricByField } from "../../utils/sensorMetrics";
import UserContext from "../../contexts/UserContext";

const emptyAlertInput: TempAlertInput = {
  field: "",
  group: "",
  sensorId: "",
  backoffTime: 3600000,
  addEmail: true,
  addSms: false,
};

enum DialogsActionKind {
  START = "START",
  CLOSE = "CLOSE",
  ALERT_CHANGED = "ALERT_CHANGED",
  SET_ERROR = "SET_ERROR",
  DONE_LOADING = "DONE_LOADING",
  START_LOADING = "START_LOADING",
}

type OtherDialogsActions = {
  type: Exclude<DialogsActionKind, DialogsActionKind.ALERT_CHANGED>;
  payload?: string;
};

type AlertChangedAction = {
  type: DialogsActionKind.ALERT_CHANGED;
  payload: TempAlertInput;
};

type DialogsAction = AlertChangedAction | OtherDialogsActions;

type DialogsState = {
  step: number;
  alertFields: TempAlertInput;
  error?: string;
  loading: boolean;
  started: boolean;
};

function dialogsReducer(
  state: DialogsState,
  action: DialogsAction,
): DialogsState {
  switch (action.type) {
    case DialogsActionKind.START:
      return {
        ...state,
        step: 0,
        started: true,
        alertFields: emptyAlertInput,
      };
    case DialogsActionKind.ALERT_CHANGED:
      return {
        ...state,
        alertFields: action.payload,
      };
    case DialogsActionKind.CLOSE:
      return {
        ...state,
        step: 0,
        alertFields: emptyAlertInput,
        loading: false,
        started: false,
      };
    case DialogsActionKind.SET_ERROR:
      return { ...state, error: action.payload };
    case DialogsActionKind.START_LOADING:
      return { ...state, loading: true };
    case DialogsActionKind.DONE_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

type Props = {
  open: boolean;
  sensors: Sensor[] | undefined;
  onClose: (updated?: boolean) => void;
};

export default function AddSensorAlertDialog({
  open,
  sensors,
  onClose,
}: Props) {
  const { user } = useContext(UserContext);
  const [createSensorAlert] = useMutation(CreateSensorAlertDocument, {
    refetchQueries: [ListSensorsWithAlertsDocument],
  });
  const submitRef = useRef<HTMLButtonElement>(null);
  const [state, dispatch] = useReducer(dialogsReducer, {
    step: 0,
    alertFields: emptyAlertInput,
    loading: false,
    started: false,
  });

  useEffect(() => {
    if (open) dispatch({ type: DialogsActionKind.START });
  }, [open]);

  if (!user) return null;

  const alertMetric = getSensorMetricByField(user, state.alertFields.field);

  async function handleSubmit() {
    const vars: CreateSensorAlertMutationVariables = {
      input: {
        ...state.alertFields,
        backoffTime: state.alertFields.backoffTime || 3600000,
        maxThres: !isNaN(parseFloat(state.alertFields.maxThres || ""))
          ? alertMetric?.convertBack(
              parseFloat(state.alertFields.maxThres || ""),
            )
          : undefined,
        minThres: !isNaN(parseFloat(state.alertFields.minThres || ""))
          ? alertMetric?.convertBack(
              parseFloat(state.alertFields.minThres || ""),
            )
          : undefined,
      },
    };
    dispatch({ type: DialogsActionKind.START_LOADING });
    try {
      await createSensorAlert({ variables: vars });
    } catch (_) {
      dispatch({
        type: DialogsActionKind.SET_ERROR,
        payload: "Error Creating Alert",
      });
      dispatch({ type: DialogsActionKind.DONE_LOADING });
      return;
    }
    dispatch({ type: DialogsActionKind.DONE_LOADING });
    closeAndClear(true);
  }

  function closeAndClear(updated?: boolean) {
    dispatch({ type: DialogsActionKind.CLOSE });
    onClose(updated);
  }

  function submitDisabled() {
    return (
      !state.alertFields.field ||
      !state.alertFields.sensorId ||
      !state.alertFields.backoffTime
    );
  }

  return (
    <Dialog open={open} onClose={() => closeAndClear()}>
      <DialogTitle>Add New Alert</DialogTitle>
      <DialogContent>
        <AddSensorAlertInputs
          fields={state.alertFields}
          onInputChange={(fields) =>
            dispatch({
              type: DialogsActionKind.ALERT_CHANGED,
              payload: fields,
            })
          }
          sensors={sensors}
        />
        {state.error && (
          <ClosableErrorMessage
            message={state.error}
            onClose={() =>
              dispatch({ type: DialogsActionKind.SET_ERROR, payload: "" })
            }
          />
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button disabled={state.loading} onClick={() => closeAndClear()}>
          Cancel
        </Button>
        <Button
          disabled={submitDisabled()}
          variant="contained"
          onClick={handleSubmit}
          ref={submitRef}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
