import { Grid, Typography } from "@mui/material";
import AllReadingsTables from "../components/sensor/AllReadingsTables";
import { Box } from "@mui/system";
import GroupContext from "../contexts/GroupContext";
import { useContext } from "react";

export default function Home() {
  const group = useContext(GroupContext);

  if (!group)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={"100px"}
      >
        <Box
          component="img"
          src="/missing_data.svg"
          alt="Missing Data Image"
          width="200px"
          height="200px"
        />
        <Typography>You don't have any data!</Typography>
      </Box>
    );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AllReadingsTables />
      </Grid>
    </Grid>
  );
}
