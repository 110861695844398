import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Experimental_CssVarsProvider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo } from "react";
import { ListGroupsDocument } from "../generated/graphql";
import { User } from "../hooks/useCurrentUser";

type Props = {
  value: string;
  onChange: (group: string) => void;
  user: User;
};

export type Group = { group: string; name?: string };

export default function GroupSelector({ value, onChange, user }: Props) {
  const { data, loading: groupsLoading, error } = useQuery(ListGroupsDocument);

  if (error) console.error(error);

  const groups: Group[] = useMemo(() => {
    const gs: Group[] = [];
    if (!data?.listGroups || !user) return gs;

    const tokenGroups = (user.cognitoUser?.signInUserSession.accessToken
      .payload?.["cognito:groups"] || []) as string[];
    let userGroups: Group[] = [];

    if (tokenGroups) {
      userGroups = tokenGroups.map((id) => ({
        group: id,
      }));
    }
    userGroups.unshift(...gs);

    for (const groupItem of data.listGroups) {
      if (!groupItem) continue;
      const userGroup = userGroups.find((v) => v.group === groupItem.group);
      if (userGroup) userGroup.name = groupItem.name;
    }

    return userGroups;
  }, [user, data]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    let newVal = e.target.value;
    onChange(newVal);
  };

  if (groupsLoading)
    return <CircularProgress size={30} sx={{ color: "white" }} />;
  if (groups.length < 1) return null;

  return (
    // eslint-disable-next-line
    <Experimental_CssVarsProvider defaultMode={"dark"}>
      <FormControl sx={{ maxWidth: 150 }}>
        <InputLabel>Group</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          label="Group"
          size="small"
        >
          {Object.values(groups).map((val) => {
            return (
              <MenuItem key={val.group} value={val.group}>
                {val.name ? val.name : val.group}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Experimental_CssVarsProvider>
  );
}
