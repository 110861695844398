import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { Sensor } from "../../generated/graphql";
import { getSensorMetrics } from "../../utils/sensorMetrics";

type Props = {
  open: boolean;
  onClose: () => void;
  sensor: Sensor;
};

export default function LastReadingDialog({ open, onClose, sensor }: Props) {
  const { user } = useContext(UserContext);
  const lastReading = sensor.sensorReadings?.items?.[0];
  const theme = useTheme();

  let content = (
    <Typography>This sensor hasn't taken any readings yet!</Typography>
  );

  if (lastReading && user) {
    content = (
      <List>
        {getSensorMetrics(user).map((v) => {
          const val = lastReading[v.dbField];
          if (val == null || typeof val !== "number") return null;

          return (
            <ListItem key={v.dbField}>
              <ListItemAvatar>
                <Avatar>{v.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  v.convert(lastReading[v.dbField] as number)?.toFixed(2) || "-"
                }
                secondary={v.name}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle component="div">
        <Typography variant={"h6"}>Last Reading</Typography>
        <Typography color={theme.palette.grey[700]}>{sensor.name}</Typography>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
