import {
  Box,
  Button,
  ClickAwayListener,
  InputLabel,
  Popper,
} from "@mui/material";
import { useRef, useState } from "react";
import { SketchPicker } from "react-color";

type Props = {
  color: string;
  onChange?: (color: string) => void;
  onClose?: (color: string) => void;
  label?: string;
  allowChange?: boolean;
};

export default function ColorPicker({
  color,
  onChange,
  onClose,
  label,
  allowChange,
}: Props) {
  const colorButton = useRef<HTMLButtonElement>(null);
  const [internalColor, setInternalColor] = useState(color);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column">
      {label && <InputLabel>{label}</InputLabel>}
      {allowChange ? (
        <Button
          onClick={() => setColorPickerOpen(true)}
          ref={colorButton}
          variant="contained"
          sx={{
            height: "25px",
            bgcolor: color,
            "&:hover": {
              bgcolor: color,
            },
          }}
        />
      ) : (
        <Box sx={{ height: "25px", bgcolor: color, borderRadius: 1 }} />
      )}
      {allowChange && colorPickerOpen && (
        <ClickAwayListener
          onClickAway={() => {
            setColorPickerOpen(false);
            onClose && onClose(internalColor);
          }}
        >
          <Popper
            open={colorPickerOpen}
            anchorEl={colorButton.current}
            style={{ zIndex: 2 }}
          >
            <SketchPicker
              color={internalColor}
              onChange={(c) => {
                setInternalColor(c.hex);
                onChange && onChange(c.hex);
              }}
            />
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
}
