import { Box, Grid, IconButton, Typography } from "@mui/material";
import EditableText from "../EditableText";
import { Auth } from "@aws-amplify/auth";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { Edit } from "@mui/icons-material";
import UpdatePhoneNumberDialog from "../UpdatePhoneNumberDialog";
import { useState } from "react";
import UpdateEmailDialog from "../UpdateEmailDialog";
import { User } from "../../hooks/useCurrentUser";

type Props = { user: User; refetchUser: () => Promise<void> };

const PersonalInformation = ({ user, refetchUser }: Props) => {
  const attrs = user?.cognitoUser?.attributes;
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const formattedPhoneNumber = formatPhoneNumber(
    attrs?.phone_number?.replace("+1", ""),
  );

  async function updateUser(value: string, attribute?: string) {
    attribute &&
      (await Auth.updateUserAttributes(user.cognitoUser, {
        [attribute]: value,
      }));
    await refetchUser();
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontWeight={500} fontSize={20} mb={2}>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            Given Name
          </Typography>
          <Box alignSelf="flex-start">
            <EditableText
              value={attrs?.given_name}
              onEdit={async (newValue) =>
                await updateUser(newValue, "given_name")
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            Family Name
          </Typography>
          <Box alignSelf="flex-start">
            <EditableText
              value={attrs?.family_name}
              onEdit={async (newValue) =>
                await updateUser(newValue, "family_name")
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            Email
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            alignSelf="flex-start"
            flexDirection="row"
            gap={1}
          >
            <Typography fontSize={18}>{attrs?.email}</Typography>
            <IconButton onClick={() => setEmailDialogOpen(true)} size="small">
              <Edit fontSize="small" />
            </IconButton>
            <UpdateEmailDialog
              open={emailDialogOpen}
              user={user}
              refetchUser={refetchUser}
              onClose={() => setEmailDialogOpen(false)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column">
          <Typography fontSize={12} color={(theme) => theme.palette.grey[600]}>
            Phone
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            alignSelf="flex-start"
            flexDirection="row"
            gap={1}
          >
            {formattedPhoneNumber ? (
              <Typography fontSize={18}>+1 {formattedPhoneNumber}</Typography>
            ) : (
              <Typography fontSize={18}>-</Typography>
            )}
            <IconButton onClick={() => setPhoneDialogOpen(true)} size="small">
              <Edit fontSize="small" />
            </IconButton>
            <UpdatePhoneNumberDialog
              open={phoneDialogOpen}
              user={user}
              refetchUser={refetchUser}
              onClose={() => setPhoneDialogOpen(false)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInformation;
