import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import {
  ChartTime,
  ChartTimesData,
  generateChartTime,
} from "./SensorReadingsTable";
import "react-date-range/dist/styles.css";
import moment from "moment";
import { DateRange, Range } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useState } from "react";
import { CalendarMonth } from "@mui/icons-material";

export enum HistoryValues {
  HOUR = 1,
  DAY = 24,
  WEEK = 24 * 7,
  THIRTYDAYS = 24 * 30,
  CUSTOM = 0,
}

type Props = {
  value: ChartTime;
  onChange: (value: ChartTime) => void;
};

export default function HistorySelector({ value, onChange }: Props) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [range, setRange] = useState<Range>({
    startDate: value.start.toDate(),
    endDate: value.end.toDate(),
  });

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    let newVal = typeof e === "number" ? e : e.target.value;
    if (typeof newVal === "string") newVal = parseInt(newVal);
    if (newVal === HistoryValues.CUSTOM) {
      setPickerOpen(true);
      return;
    }
    handleChange(newVal);
  };

  const handleChange = (newVal: number) => {
    if (newVal === HistoryValues.CUSTOM) {
      const start = moment(range.startDate).startOf("day");
      const end = moment(range.endDate).endOf("day");
      onChange(generateChartTime(newVal, end, start));
    } else {
      onChange(generateChartTime(newVal, value.end));
    }
  };

  const options = Object.values(HistoryValues);

  return (
    <Box
      display="flex"
      gap={1}
      mr={value.hours === HistoryValues.CUSTOM ? 0 : 1}
    >
      <FormControl>
        <InputLabel>Chart Range</InputLabel>
        <Select
          value={value.hours}
          onChange={handleSelectChange}
          label="Chart Range"
          size="small"
        >
          {options.map((val) => {
            if (typeof val === "string") return null;
            return (
              <MenuItem key={val} value={val}>
                {ChartTimesData[val].label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {value.hours === HistoryValues.CUSTOM && (
        <IconButton color="inherit" onClick={() => setPickerOpen(true)}>
          <CalendarMonth />
        </IconButton>
      )}
      <Dialog open={pickerOpen} onClose={() => setPickerOpen(false)}>
        <DialogContent sx={{ p: 0 }}>
          <DateRange
            ranges={[range]}
            onChange={(ranges) => {
              setRange(ranges.range1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPickerOpen(false)}>Cancel</Button>
          <Button
            disabled={
              moment(range.endDate).diff(moment(range.startDate)) >
              1000 * 60 * 60 * 24 * 31
            }
            variant="contained"
            onClick={() => {
              handleChange(HistoryValues.CUSTOM);
            }}
          >
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
