import {
  Delete,
  Visibility,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar,
} from "@mui/icons-material";
import { Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import CardField from "../CardField";
import ConfirmDeleteTooltip from "../ConfirmDeleteTooltip";
import ColorPicker from "../ColorPicker";
import LastReadingDialog from "./LastReadingDialog";
import {
  DeleteSensorDocument,
  DeleteSensorMutationVariables,
  ListSensorsWithLastReadingDocument,
  Sensor,
  UpdateSensorDocument,
  UpdateSensorMutationVariables,
} from "../../generated/graphql";
import { useMutation } from "@apollo/client";
import GroupContext from "../../contexts/GroupContext";

type Props = {
  sensor: Sensor;
  isAdmin?: boolean;
};

const getWifiSignalProps = (wifiRssi: number | undefined) => {
  if (wifiRssi === undefined) {
    return { icon: SignalWifi0Bar, color: "grey" };
  } else if (wifiRssi > -50) {
    return { icon: SignalWifi4Bar, color: "green" };
  } else if (wifiRssi > -60) {
    return { icon: SignalWifi3Bar, color: "blue" };
  } else if (wifiRssi > -70) {
    return { icon: SignalWifi2Bar, color: "#FFAE42" };
  } else {
    return { icon: SignalWifi1Bar, color: "red" };
  }
};

export default function SensorRow({ sensor, isAdmin }: Props) {
  const group = useContext(GroupContext);
  const [lastReadingOpen, setLastReadingOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const lastReading = sensor.sensorReadings?.items?.[0];
  const [updateSensor] = useMutation(UpdateSensorDocument);
  const [deleteSensor] = useMutation(DeleteSensorDocument, {
    refetchQueries: [ListSensorsWithLastReadingDocument],
  });
  const { icon: WifiIcon, color } = getWifiSignalProps(
    lastReading?.wifiRssi || undefined,
  );

  function deleteHandler() {
    setConfirmDeleteOpen(false);
    const vars: DeleteSensorMutationVariables = {
      input: {
        id: sensor.id,
        group,
      },
    };

    deleteSensor({ variables: vars });
  }

  function updateHandler(field: string) {
    return async (newValue: string) => {
      const vars: UpdateSensorMutationVariables = {
        input: { id: sensor.id, group, [field]: newValue || null },
      };

      await updateSensor({ variables: vars });
    };
  }

  return (
    <TableRow>
      <TableCell>
        <CardField
          value={sensor.name}
          onEdit={
            isAdmin ? (newValue) => updateHandler("name")(newValue) : undefined
          }
        />
      </TableCell>
      <TableCell>
        <CardField key={sensor.id} value={sensor.id} />
      </TableCell>
      <TableCell>
        <div style={{ display: "flex", alignItems: "center" }}>
          <WifiIcon style={{ color }} />
        </div>
      </TableCell>
      <TableCell>
        <Chip
          label={
            lastReading
              ? new Date(lastReading.timestamp).toLocaleString()
              : "N/A"
          }
          color="secondary"
          onDelete={lastReading ? () => setLastReadingOpen(true) : undefined}
          deleteIcon={<Visibility />}
        />
        <LastReadingDialog
          open={lastReadingOpen}
          onClose={() => setLastReadingOpen(false)}
          sensor={sensor}
        />
      </TableCell>
      <TableCell>
        <ColorPicker
          color={sensor.color}
          onClose={updateHandler("color")}
          allowChange={isAdmin}
        />
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {isAdmin && (
          <ConfirmDeleteTooltip
            open={confirmDeleteOpen}
            onCancel={() => setConfirmDeleteOpen(false)}
            onConfirm={deleteHandler}
            onClose={() => setConfirmDeleteOpen(false)}
          >
            <div>
              <Tooltip title="Delete">
                <IconButton onClick={() => setConfirmDeleteOpen(true)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </div>
          </ConfirmDeleteTooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
