import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useSearchParams, Outlet } from "react-router-dom";
import AvatarDropdown from "../components/AvatarDropdown";
import { Footer } from "../components/Footer";
import GroupSelector from "../components/GroupSelector";
import GroupContext from "../contexts/GroupContext";
import UserContext, { UserState } from "../contexts/UserContext";

type Props = {
  userState: UserState;
};

export default function MainNavigation({ userState }: Props) {
  const { user } = userState;
  const theme = useTheme();
  const [params, setParams] = useSearchParams();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const storageGroup = (
    (user?.cognitoUser?.signInUserSession.accessToken.payload?.[
      "cognito:groups"
    ] || []) as string[]
  ).find((v) => localStorage.getItem("group") === v);
  const [group, setGroup] = useState(
    storageGroup ||
      user?.cognitoUser?.signInUserSession.accessToken.payload?.[
        "cognito:groups"
      ]?.[0] ||
      "",
  );

  const paramsGroup = useMemo(() => params.get("group"), [params]);
  const groups = useMemo(
    () =>
      user?.cognitoUser?.signInUserSession.accessToken.payload?.[
        "cognito:groups"
      ],
    [user],
  );

  useEffect(() => {
    if (paramsGroup && groups.includes(paramsGroup)) {
      handleGroupChange(paramsGroup);
    }
    setParams(
      (p) => {
        p.delete("group");
        return p;
      },
      { replace: true },
    );
  }, [paramsGroup, setParams, groups]);

  function handleGroupChange(group: string) {
    setGroup(group);
    localStorage.setItem("group", group);
  }

  if (!user) {
    return null;
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" flex={1} alignItems="center" height="64px">
            {isSmall ? (
              <Box
                width="40px"
                component="img"
                src="/mabel_logo_white_icon.png"
              />
            ) : (
              <Box
                width="250px"
                component="img"
                src="/mabel_logo_white_wide.png"
              />
            )}
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <GroupSelector
              value={group}
              onChange={handleGroupChange}
              user={user}
            />
            <AvatarDropdown user={user} />
          </Box>
        </Toolbar>
      </AppBar>
      <UserContext.Provider value={userState}>
        <GroupContext.Provider value={group}>
          <Outlet />
        </GroupContext.Provider>
      </UserContext.Provider>
      <Box flex={1} minHeight={50} />
      <Footer />
    </Box>
  );
}
