import { Battery4Bar, Thermostat, Water } from "@mui/icons-material";
import { GiBubbles } from "react-icons/gi";
import { RiWaterPercentFill } from "react-icons/ri";
import { TbSalt } from "react-icons/tb";
import { SensorReading } from "../generated/graphql";
import { GoContainer } from "react-icons/go";
import { User } from "../hooks/useCurrentUser";
import { convertCelsiusToFahrenheit, convertFahrenheitToCelsius } from "./math";
import { getPreferences } from "./preferences";

export type SensorMetric = {
  field: string;
  name: string;
  dbField: keyof SensorReading;
  icon: JSX.Element;
  hideTable?: boolean;
  convert: (value?: number | null) => number | null | undefined;
  convertBack: (value?: number | null) => number | null | undefined;
};

export const getSensorMetrics = (user: User): SensorMetric[] => {
  const preferences = getPreferences(user);

  return [
    {
      field: "TempC1",
      name: "Temperature (" + (preferences.tempUnit || "C") + ")",
      dbField: "temperature",
      icon: <Thermostat />,
      convert: (value) =>
        (preferences.tempUnit || "C") === "C"
          ? value
          : convertCelsiusToFahrenheit(value),
      convertBack: (value) =>
        (preferences.tempUnit || "C") === "C"
          ? value
          : convertFahrenheitToCelsius(value),
    },
    {
      field: "Humidity",
      name: "Humidity (%)",
      dbField: "humidity",
      icon: <RiWaterPercentFill />,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "BatV",
      name: "Battery (V)",
      dbField: "battery",
      icon: <Battery4Bar />,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "Conductivity",
      name: "Salinity (ppt)",
      dbField: "conductivity",
      icon: <TbSalt />,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "DissolvedOxygen",
      name: "Dissolved Oxygen (%)",
      dbField: "dissolvedOxygen",
      icon: <GiBubbles />,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "pH",
      name: "pH",
      dbField: "pH",
      icon: <Water />,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "CaseTemp",
      name: "Case Temperature",
      dbField: "caseTemperature",
      icon: <GoContainer />,
      hideTable: true,
      convert: (value) => value,
      convertBack: (value) => value,
    },
    {
      field: "CaseHumidity",
      name: "Case Humidity",
      dbField: "caseHumidity",
      icon: <GoContainer />,
      hideTable: true,
      convert: (value) => value,
      convertBack: (value) => value,
    },
  ];
};

export const getSensorMetricByField = (
  user: User,
  field: string,
): SensorMetric | undefined => {
  const metrics = getSensorMetrics(user);
  return metrics.find((m) => m.field === field);
};

export const getSensorMetricByName = (
  user: User,
  name: string,
): SensorMetric | undefined => {
  const metrics = getSensorMetrics(user);
  return metrics.find((m) => m.name === name);
};
