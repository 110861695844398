import {
  Box,
  BoxProps,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isValidEmail } from "../utils/errorHelpers";
import { handleEnterSubmit } from "../utils/handlers";

export type UpdateEmailInputValues = {
  email: string;
  code: string;
  badCode?: boolean;
  codeSent?: boolean;
};

type Props = {
  inputs: UpdateEmailInputValues;
  onInputsChange: (input: UpdateEmailInputValues) => void;
  submitRef: React.RefObject<HTMLButtonElement>;
  emailLoading?: boolean;
  emailBlocked?: boolean;
} & BoxProps;

export default function UpdateEmailInputs({
  inputs,
  onInputsChange,
  submitRef,
  emailLoading,
  emailBlocked,
  ...boxProps
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let helperText = "";
  if (inputs.email && !isValidEmail(inputs.email)) helperText = "Invalid Email";
  if (isValidEmail(inputs.email))
    helperText = "Email address must be verified before it can be added";
  if (emailBlocked) helperText = "This email is blocked";

  return (
    <Box
      width={isSmall ? "100%" : "400px"}
      display="flex"
      flexDirection="column"
      gap={2}
      {...boxProps}
    >
      <TextField
        margin="dense"
        value={inputs.email}
        onChange={(e) => onInputsChange({ ...inputs, email: e.target.value })}
        label="Email"
        type="email"
        helperText={helperText}
        onKeyDown={handleEnterSubmit(submitRef)}
        required
        error={(!!inputs.email && !isValidEmail(inputs.email)) || emailBlocked}
        InputProps={{
          endAdornment: emailLoading && <CircularProgress size={20} />,
        }}
      />
      {inputs.codeSent && (
        <TextField
          value={inputs.code}
          onChange={(e) => onInputsChange({ ...inputs, code: e.target.value })}
          onKeyDown={handleEnterSubmit(submitRef)}
          label="Verification Code"
          type="text"
          error={inputs.badCode}
        />
      )}
    </Box>
  );
}
