import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  ListSensorsWithAlertsDocument,
  Sensor,
  UpdateSensorDocument,
} from "../../generated/graphql";
import { isNotNull } from "../../utils/typeGuards";
import AddSensorAlertDialog from "./AddSensorAlertDialog";
import SensorAlertRow from "./SensorAlertRow";
import { useMutation, useQuery } from "@apollo/client";
import GroupContext from "../../contexts/GroupContext";
import ErrorMessage from "../ErrorMessage";
import UserContext from "../../contexts/UserContext";

export default function SensorAlertsList() {
  const group = useContext(GroupContext);
  const { user } = useContext(UserContext);
  const { loading, data, error } = useQuery(ListSensorsWithAlertsDocument, {
    variables: { group },
  });
  const [updateSensor] = useMutation(UpdateSensorDocument, {});
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const isAdmin =
    user?.cognitoUser?.signInUserSession.idToken.payload["custom:isAdmin"];

  const sensorsWithAlerts = (
    data?.listSensors?.items as Sensor[] | undefined
  )?.filter(isNotNull);

  let content = <Typography alignSelf="center">No Sensor Alerts!</Typography>;
  if (error) {
    content = <ErrorMessage />;
    console.error(error);
  }
  if (loading && !data) content = <AlertListSkeleton />;
  else if (sensorsWithAlerts && sensorsWithAlerts.length >= 1) {
    content = (
      <Grid container spacing={2}>
        {sensorsWithAlerts.map((sensor) => (
          <Grid
            key={sensor.id}
            item
            xs={12}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Box justifyContent="space-between" display="flex">
              <Typography fontWeight="bold">{sensor.name}</Typography>
              {isAdmin && (
                <FormControlLabel
                  label={<Typography fontSize={14}>Offline Alerts</Typography>}
                  control={
                    <Switch
                      size="small"
                      checked={!sensor.offlineAlertsDisabled}
                      onChange={(_, checked) => {
                        const input = {
                          group: sensor.group,
                          id: sensor.id,
                          offlineAlertsDisabled: !checked,
                        };

                        updateSensor({
                          variables: {
                            input,
                          },
                          optimisticResponse: {
                            updateSensor: { ...input, ...sensor },
                          },
                        }).catch((e) => console.error(e));
                      }}
                    />
                  }
                />
              )}
            </Box>
            <SensorAlertsTable sensor={sensor} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box flex={0} alignSelf="flex-end" pb={1}>
        <Button
          sx={{ mb: 2 }}
          disabled={loading && !data}
          variant="contained"
          onClick={() => setAddDialogOpen(true)}
        >
          Add New Alert
        </Button>
      </Box>
      {content}
      <AddSensorAlertDialog
        open={addDialogOpen}
        onClose={() => {
          setAddDialogOpen(false);
        }}
        sensors={data?.listSensors?.items as Sensor[] | undefined}
      />
    </Box>
  );
}

type TableProps = {
  sensor: Sensor;
};

const SensorAlertsTable = ({ sensor }: TableProps) => {
  return (
    <TableContainer component={Paper} color="black">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Last Triggered</TableCell>
            <TableCell>Min</TableCell>
            <TableCell>Max</TableCell>
            <TableCell>Re-Alert Limit (Mins)</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">SMS</TableCell>
            <TableCell align="center">Disabled</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        {sensor.alerts?.items && sensor.alerts.items.length > 0 && (
          <TableBody>
            {sensor.alerts?.items
              ?.filter(isNotNull)
              .map((alert) => <SensorAlertRow key={alert.id} alert={alert} />)}
          </TableBody>
        )}
      </Table>
      {sensor.alerts?.items && sensor.alerts.items.length === 0 && (
        <Box width="100%" display="flex" justifyContent="center">
          <Typography fontSize={14} m={2}>
            No Alerts
          </Typography>
        </Box>
      )}
    </TableContainer>
  );
};

const AlertListSkeleton = () => {
  const fakeSensor = {
    __typename: "Sensor",
    alerts: {
      items: [
        { id: "124", field: "1234", backoffTime: 1243 },
        { id: "3421", field: "2233", backoffTime: 5142 },
      ],
    },
  } as Sensor;

  return (
    <Box display="flex" flexDirection="column" data-testid="loading-skeleton">
      <Skeleton>
        <Typography>Sensor Name</Typography>
      </Skeleton>
      <Skeleton width="100%">
        <SensorAlertsTable sensor={fakeSensor} />
      </Skeleton>
      <Skeleton>
        <Typography>Sensor Name</Typography>
      </Skeleton>
      <Skeleton width="100%">
        <SensorAlertsTable sensor={fakeSensor} />
      </Skeleton>
    </Box>
  );
};
