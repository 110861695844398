import { Avatar, Box, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import getInitials from "../../utils/getInitials";
import { User } from "../../hooks/useCurrentUser";

type Props = { user: User };

const ProfileHeading = ({ user }: Props) => {
  const initials = getInitials(user);

  return (
    <Box flexDirection="row" display="flex" alignItems="center" gap={2}>
      <Avatar
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          color: "white",
          width: 80,
          height: 80,
        }}
      >
        {initials ? (
          <Typography fontSize={36}>{initials}</Typography>
        ) : (
          <AccountCircleIcon data-testid="AccountIcon" fontSize="large" />
        )}
      </Avatar>
      <Typography fontSize={20}>
        {user?.cognitoUser?.attributes?.given_name +
          " " +
          user?.cognitoUser?.attributes?.family_name}
      </Typography>
    </Box>
  );
};

export default ProfileHeading;
