import { Box, Divider, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  PersonalInformation,
  Preferences,
  ProfileHeading,
} from "../components/profile";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

export default function Profile() {
  const navigation = useNavigate();
  const { user, refetch } = useContext(UserContext);
  if (!user) return null;

  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        flex={1}
        maxWidth={1200}
        width="100%"
        gap={2}
      >
        <Box display="flex" alignItems="center" pb={2} gap={2}>
          <IconButton
            onClick={() =>
              window.history.state.idx !== 0 ? navigation(-1) : navigation("/")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography component="h5" variant="h5">
            My Profile
          </Typography>
        </Box>
        <ProfileHeading user={user} />
        <Divider />
        <PersonalInformation user={user} refetchUser={refetch} />
        <Divider />
        <Preferences user={user} refetchUser={refetch} />
      </Box>
    </Box>
  );
}
