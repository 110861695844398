import { Edit } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useState } from "react";
import EditingTooltip, { EditableTooltipTypes } from "./EditingTooltip";

export type LabelValuePair = { label: string; value: string };

type CardFieldProps = {
  value?: string;
  onEdit?: (newVal: string) => Promise<void>;
  type?: EditableTooltipTypes;
  options?: LabelValuePair[];
  allowClear?: boolean;
};

const CardField = ({
  value,
  onEdit,
  type,
  options,
  allowClear,
}: CardFieldProps) => {
  const [editOpen, setEditOpen] = useState(false);

  function handleEditOpen() {
    setEditOpen(true);
  }

  return onEdit ? (
    <EditingTooltip
      open={editOpen}
      initialValue={value}
      onClose={async (newVal) => {
        (newVal || (allowClear && newVal === "")) && (await onEdit(newVal));
        setEditOpen(false);
      }}
      type={type}
      options={options}
    >
      <Chip
        sx={{ flex: 1 }}
        label={value ? value : "-"}
        color="secondary"
        onDelete={() => handleEditOpen()}
        deleteIcon={<Edit />}
      />
    </EditingTooltip>
  ) : (
    <Chip label={value ? value : "-"} color="secondary" />
  );
};

export default CardField;
