import { ArrowBack, Refresh } from "@mui/icons-material";
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import HistorySelector from "./HistorySelector";
import { ChartTime } from "./SensorReadingsTable";
import { Sensor } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

type Props = {
  sensors?: Sensor[];
  onRefresh: () => void;
  timeData: ChartTime;
  onTimeChange: (timeData: ChartTime) => void;
};

export default function FullScreenSensor({
  onRefresh,
  timeData,
  onTimeChange,
}: Props) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const iconButtons = (
    <Box>
      {smallScreen && (
        <IconButton color="inherit" onClick={() => navigate("/")} title="Back">
          <ArrowBack />
        </IconButton>
      )}
      <IconButton color="inherit" onClick={onRefresh}>
        <Refresh />
      </IconButton>
    </Box>
  );

  let buttons = (
    <Grid item container sm={8} display="flex" justifyContent="flex-end">
      <Grid item>
        <HistorySelector value={timeData} onChange={onTimeChange} />
      </Grid>
      <Grid item>{iconButtons}</Grid>
    </Grid>
  );

  const backButton = (
    <Grid item>
      <IconButton color="inherit" onClick={() => navigate("/")} title="Back">
        <ArrowBack />
      </IconButton>
    </Grid>
  );

  if (smallScreen) {
    buttons = (
      <Grid container item xs={12} spacing={1} display="flex">
        <Grid item flex={1}>
          {iconButtons}
        </Grid>
        <Grid item>
          <HistorySelector value={timeData} onChange={onTimeChange} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        {!smallScreen ? (
          <Grid item xs={12} sm={4} display="flex" justifyContent="flex-start">
            {backButton}
          </Grid>
        ) : (
          <Grid item xs={12} sm={4}></Grid>
        )}
        {buttons}
      </Grid>
    </Box>
  );
}
