import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { wasCancelled } from "../../utils/errorHelpers";
import moment, { now } from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isNotNull } from "../../utils/typeGuards";
import { createCSVForReadings } from "../../utils/csvHelpers";
import { CSVLink } from "react-csv";
import {
  InputMaybe,
  ListSensorReadingsDocument,
  ListSensorReadingsQueryVariables,
  Sensor,
  SensorReading,
} from "../../generated/graphql";
import { useLazyQuery } from "@apollo/client";
import GroupContext from "../../contexts/GroupContext";

type Props = {
  open: boolean;
  onClose: () => void;
  sensors?: Sensor[];
};

const startYear = 2021;

export default function DownloadSensorDataDialog({
  open,
  onClose,
  sensors,
}: Props) {
  const [listReadings] = useLazyQuery(ListSensorReadingsDocument);
  const [loading, setLoading] = useState(false);
  const group = useContext(GroupContext);
  const [year, setYear] = useState(moment(now()).year());
  const [sensor, setSensor] = useState("");
  const [csvData, setCsvData] = useState<any[] | undefined>();
  const csvRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const currentSensor = sensors?.find((v) => v.id === sensor);
  const years = [...Array(moment(now()).year() - startYear + 1).keys()].map(
    (v) => startYear + v
  );

  const closeAndClear = useCallback(() => {
    setYear(moment(now()).year());
    setCsvData(undefined);
    setSensor("");
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (csvData === undefined) return;
    csvRef.current?.link.click();

    closeAndClear();
  }, [closeAndClear, csvData]);

  const handleDownloadClicked = async () => {
    const csv = await fetchReadings();
    if (csv) {
      setCsvData(csv);
    }
  };

  const fetchReadings = async () => {
    if (!currentSensor) return;
    setLoading(true);

    const startOfYear = moment()
      .set("year", year)
      .startOf("year")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    const endOfYear = moment()
      .set("year", year)
      .endOf("year")
      .format("YYYY-MM-DDTHH:mm:ssZ");

    let data: SensorReading[] | undefined = [];

    try {
      let nextToken: InputMaybe<string> | undefined = null;
      do {
        const variables: ListSensorReadingsQueryVariables = {
          between: [startOfYear, endOfYear],
          sensorId: currentSensor.id,
          group,
          limit: 1000,
          nextToken,
        };
        let ret = await listReadings({ variables });
        if (ret.data?.listSensorReadings.items)
          data.push(
            ...ret.data.listSensorReadings.items
              .filter(isNotNull)
              .map((v) => ({ ...v, sensorId: currentSensor.id }))
          );
        nextToken = ret.data?.listSensorReadings.nextToken;
      } while (nextToken);
    } catch (error) {
      if (!wasCancelled(error)) console.error(error);
    }

    const csv = createCSVForReadings(data);
    setLoading(false);
    return csv;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Download All Sensor Readings</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Sensor"
            select
            value={sensor}
            sx={{ marginTop: 1 }}
            fullWidth
            onChange={(e) => setSensor(e.target.value)}
          >
            {sensors?.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            )) || <MenuItem value="Sensor" disabled></MenuItem>}
          </TextField>
          <TextField
            label="Year"
            value={year}
            select
            fullWidth
            onChange={(e) => setYear(parseInt(e.target.value))}
          >
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndClear}>Close</Button>
        <Button
          disabled={!currentSensor || loading}
          variant="contained"
          onClick={handleDownloadClicked}
          sx={{ width: 110 }}
        >
          {loading ? <CircularProgress size={25} /> : "Download"}
        </Button>
      </DialogActions>
      <CSVLink
        data={csvData || ""}
        filename={currentSensor?.name.replace(/\./g, "") + "_" + year + ".csv"}
        ref={csvRef}
      />
    </Dialog>
  );
}
